import React, { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Button } from 'modules/common';
import { useAuth0 } from '@auth0/auth0-react';

const LoginContainer = ({ children }) => {
  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in</h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">{children}</div>
      </div>
    </div>
  );
};

const Auth0Login = ({ redirect, error }: { redirect: boolean; error?: string }) => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      logout({ logoutParams: { returnTo: `${window.location.origin}/login${!redirect ? '?redirect=false' : ''}` } });
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated && redirect) {
      loginWithRedirect();
    }
  }, [isAuthenticated]);

  if (redirect) return null;

  return (
    <div>
      <LoginContainer>
        <div className="flex items-center justify-center">
          <Button onClick={() => loginWithRedirect()}>Sign in</Button>
        </div>
      </LoginContainer>
      {error && (
        <p className="text-center text-red-500">
          {error} Please contact support{' '}
          <a className="underline" href="mailto:help@taper.ai">
            help@taper.ai
          </a>
          .
        </p>
      )}
    </div>
  );
};

const LoginPage = () => {
  const { query } = useRouter();
  const redirect = !(query?.redirect === 'false');

  return <Auth0Login redirect={redirect} error={query?.error as string} />;
};

LoginPage.getLayout = (children: ReactNode) => <>{children}</>;

export default LoginPage;
